import { AVAILABLE_FILTERS, AVAILABLE_VIEWS } from '@yojee/helpers/constants';
import { getTaskState } from '@yojee/helpers/TaskStateHelper';
const defaultState = {
  rightPanelCommand: 'collapse',
  leftPanelCommand: 'expand',
  showList: false,
  stopsList: {
    filter: AVAILABLE_FILTERS.UNASSIGNED,
    currentView: AVAILABLE_VIEWS.STOPS,
    expandedView: {
      tasks: null,
      anchorEl: null,
    },
    expandedLegs: {
      tasks: null,
      anchorEl: null,
    },
    highlightedTasks: null,
  },
  routes: {
    visibleAssigned: [], // Worker ID list to display the assigned route on the map view
    visiblePlanned: [], // Worker ID list to display the assigned route on the map view
  },
  groups: {
    visibleUnassigned: false,
  },
  assignment: {
    showConfirmationDialog: false,
    whitelistedWorkers: null,
  },
  optimisation: {
    showConfirmationDialog: false,
    showSettings: false,
    showError: false,
    instanceToDisplay: null,
    showDroppedTasksInMap: false,
  },
  hierarchyDialog: {
    show: false,
    orderItemId: null,
  },
  orderFormDialog: {
    showSelectionDialog: false,
    show: false,
    order: null,
  },
  cancelTask: {
    showConfirmationDialog: false,
    tasks: [],
    status: 'normal',
    message: undefined,
  },
  highlightedLines: null,
  noAccessDialog: {
    show: false,
  },
  paymentDialog: {
    show: false,
  },
  workerAddDialog: {
    show: false,
  },
  vehicleAddDialog: {
    show: false,
  },
  missingInfoFixDialog: {
    show: false,
  },
  presentation: {
    refreshTimeInMinutes: 5,
    lastRefreshAt: Date.now(),
  },
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  '@@router/LOCATION_CHANGE': (state, { payload }) => {
    const { location } = payload;
    return { ...state, showList: location.pathname.includes('/explore-listview') };
  },
  TOGGLE_EXPLORE_LIST: (state, { show }) => {
    return { ...state, showList: show };
  },
  OPTIMISATION_SUCCEEDED: (state, { completed, requestId }) => {
    const newState = !completed ? state : { ...state, showList: false };
    return { ...newState, optimisation: { ...newState.optimisation, instanceToDisplay: requestId } };
  },
  RESET_ALL_STORE: () => {
    return defaultState;
  },
  SET_RIGHT_PANEL_COMMAND: (state, { rightPanelCommand }) => {
    return { ...state, rightPanelCommand };
  },
  SET_LEFT_PANEL_COMMAND: (state, { leftPanelCommand }) => {
    return { ...state, leftPanelCommand };
  },
  SET_STOPS_LIST_CONFIG: (state, { stopsList }) => {
    return { ...state, stopsList: { ...state.stopsList, ...stopsList } };
  },
  CLEAR_WORKER_FILTER: (state) => {
    return { ...state, routes: { ...state.routes, visibleAssigned: [] } };
  },
  UPDATE_WORKER_FILTER_BY_QUERY_STRING: (state, { workerIds }) => {
    return { ...state, routes: { ...state.routes, visibleAssigned: workerIds } };
  },
  SET_ROUTES_CONFIG: (state, { routes }) => {
    return { ...state, routes: { ...state.routes, ...routes } };
  },
  SET_GROUPS_CONFIG: (state, { groups }) => {
    return { ...state, groups: { ...state.assignment, ...groups } };
  },
  SET_ASSIGNMENT_CONFIG: (state, { assignment }) => {
    return { ...state, assignment: { ...state.assignment, ...assignment } };
  },
  SET_OPTIMISATION_CONFIG: (state, { optimisation }) => {
    return { ...state, optimisation: { ...state.optimisation, ...optimisation } };
  },
  SET_STOPS_LIST_EXPANDED_VIEW: (state, { expandedView }) => {
    return {
      ...state,
      stopsList: {
        ...state.stopsList,
        expandedView,
        expandedLegs: {
          tasks: null,
          anchorEl: null,
          stopId: null,
        },
      },
    };
  },
  SET_STOPS_LIST_EXPANDED_LEGS: (state, { expandedLegs }) => {
    return {
      ...state,
      stopsList: {
        ...state.stopsList,
        expandedLegs,
        expandedView: {
          tasks: null,
          anchorEl: null,
          stopId: null,
        },
      },
    };
  },
  SET_HIGHLIGHTED_TASKS: (state, { highlightedTasks }) => {
    if (highlightedTasks === null && state.stopsList.highlightedTasks === null) {
      return state;
    }

    if (
      state.stopsList.highlightedTasks !== null &&
      highlightedTasks !== null &&
      state.stopsList.highlightedTasks.filter((t) => highlightedTasks.includes(t)).length === highlightedTasks.length
    ) {
      return state;
    }

    return { ...state, stopsList: { ...state.stopsList, highlightedTasks } };
  },
  SHOW_HIERARCHY_DIALOG: (state, { orderItemId }) => {
    return { ...state, hierarchyDialog: { ...state.hierarchyDialog, show: true, orderItemId: orderItemId } };
  },
  HIDE_HIERARCHY_DIALOG: (state) => {
    return { ...state, hierarchyDialog: { ...state.hierarchyDialog, show: false, orderItemId: null } };
  },
  TOGGLE_NO_ACCESS_DIALOG: (state) => {
    return { ...state, noAccessDialog: { ...state.noAccessDialog, show: !state.noAccessDialog.show } };
  },
  TOGGLE_PAYMENT_DIALOG: (state) => {
    return { ...state, paymentDialog: { ...state.paymentDialog, show: !state.paymentDialog.show } };
  },
  UPDATE_CURRENT_VIEW: (state, { selectedView }) => {
    if (selectedView === AVAILABLE_VIEWS.ITEMS) {
      return state;
    }
    return { ...state, stopsList: { ...state.stopsList, currentView: selectedView } };
  },
  CHANGE_VIEW_BY_URL_PARAM: (state, { newView }) => {
    if (newView === AVAILABLE_VIEWS.ITEMS) {
      return state;
    }
    return { ...state, stopsList: { ...state.stopsList, currentView: newView } };
  },
  CLOSE_ORDER_FORM: (state) => {
    return { ...state, orderFormDialog: { ...state.orderFormDialog, show: false, order: null } };
  },
  OPEN_ORDER_FORM: (state, { order }) => {
    return { ...state, orderFormDialog: { ...state.orderFormDialog, show: true, order: order } };
  },
  SHOW_SELECTION_CREATE_ORDER: (state) => {
    return {
      ...state,
      orderFormDialog: { ...state.orderFormDialog, showSelectionDialog: true, show: false, order: null },
    };
  },
  CLOSE_SELECTION_CREATE_ORDER: (state) => {
    return { ...state, orderFormDialog: { ...state.orderFormDialog, showSelectionDialog: false } };
  },
  SET_HIGHLIGHTED_LINES: (state, { highlightedLines }) => {
    return { ...state, highlightedLines };
  },
  OPEN_CANCEL_TASK_CONFIRM_DIALOG: (state, { tasks, allowCancelWithAssignedTask }) => {
    const filterTasks =
      tasks &&
      tasks.filter((t) => {
        return (
          ['unassigned', 'missing info', 'reported', 'completed', 'closed'].includes(getTaskState(t)) ||
          (getTaskState(t) === 'assigned' && allowCancelWithAssignedTask)
        );
      });
    return {
      ...state,
      cancelTask: {
        ...state.cancelTask,
        showConfirmationDialog: true,
        tasks: filterTasks,
        status: 'normal',
        error: undefined,
      },
    };
  },
  CLOSE_CANCEL_TASK_CONFIRM_DIALOG: (state) => {
    return {
      ...state,
      cancelTask: { ...state.cancelTask, showConfirmationDialog: false, tasks: [], status: 'normal', error: undefined },
    };
  },
  PROCESS_CANCEL_TASKS: (state, { tasks }) => {
    return { ...state, cancelTask: { ...state.cancelTask, tasks, status: 'processing', error: undefined } };
  },
  PROCESS_CANCEL_TASKS_SUCCESS: (state, { message }) => {
    return { ...state, cancelTask: { ...state.cancelTask, status: 'success', message } };
  },
  PROCESS_CANCEL_TASKS_ERROR: (state, { error }) => {
    return { ...state, cancelTask: { ...state.cancelTask, status: 'error', message: error } };
  },
  CLEAR_CANCEL_TASK_MESSAGE: (state) => {
    return {
      ...state,
      cancelTask: {
        showConfirmationDialog: false,
        tasks: [],
        status: 'normal',
        message: undefined,
      },
    };
  },
  SHOW_WORKER_ADD_DIALOG: (state) => {
    return { ...state, workerAddDialog: { show: true } };
  },
  HIDE_WORKER_ADD_DIALOG: (state) => {
    return { ...state, workerAddDialog: { show: false } };
  },
  SHOW_VEHICLE_ADD_DIALOG: (state) => {
    return { ...state, vehicleAddDialog: { show: true } };
  },
  HIDE_VEHICLE_ADD_DIALOG: (state) => {
    return { ...state, vehicleAddDialog: { show: false } };
  },
  SHOW_MISSING_INFO_FIX_DIALOG: (state) => {
    return { ...state, missingInfoFixDialog: { show: true } };
  },
  HIDE_MISSING_INFO_DIALOG: (state) => {
    return { ...state, missingInfoFixDialog: { show: false } };
  },
  CLOSE_OPTIMISATION: (state) => {
    return { ...state, optimisation: { instanceToDisplay: null } };
  },
  ASSIGNMENT_SUCCEEDED: (state) => {
    return { ...state, optimisation: { instanceToDisplay: null } };
  },
  UPDATE_LAST_REFRESH_TIME: (state, { lastRefreshAt }) => ({
    ...state,
    presentation: {
      ...state.presentation,
      lastRefreshAt,
    },
  }),
  SET_PRESENTATION_REFRESH_INTERVAL: (state, { refreshTimeInMinutes }) => {
    return {
      ...state,
      presentation: {
        ...state.presentation,
        refreshTimeInMinutes: refreshTimeInMinutes,
      },
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
